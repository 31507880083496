import React from 'react'
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from '../components/AppLayout'
import PageInfo from '../components/PageInfo'
import {
	ArticleBody,
	ArticleCopy,
	ArticleHeaderImg,
	ArticleList,
	ArticleListItem,
	ArticlePage
} from '../components/common/Article'
import RouterLink from '../components/shared/RouterLink'
import { graphql } from 'gatsby'
import UnsplashPhotoCredit from '../components/common/UnsplashPhotoCredit'
import AnchorLink from '../components/shared/AnchorLink'
import { SchemaMarkupArticleAsSupplementalBlog } from '../components/common/SchemaMarkup'

export default function PoorTimeManagement(props) {
	let {data} = props;
	const {productName} = useSiteMetadata();

	const title = "9 Time Management Tools to Improve Your Workflow & Boost Productivity (2020)";

	return (
		<AppLayout overlayNavWhenWide>
			<PageInfo
				title={"9 Time Management Tools to Improve Your Workflow (2020) - " + productName}
				description={"When you consider your workflow, there are many useful tools to complement your time tracking. We take a look at a few."}
			/>
			<SchemaMarkupArticleAsSupplementalBlog/>

			<ArticlePage>
				<ArticleHeaderImg
					alt="Time Management Tools"
					caption={<UnsplashPhotoCredit author="Hunter Haley" href="https://unsplash.com/@hnhmarketing"/>}
					imgData={data.headerImg.childImageSharp.gatsbyImageData}
					articleTitle={title}
				/>

				<ArticleBody articleTitle={title}>

					<ArticleCopy>
						Hey there, productive go-getter! Perhaps you've read other articles in our
						{' '}
						<RouterLink to="/time-management-101/">Time Management 101</RouterLink>
						{' '}
						resource. If so, then you already know that staying
						organized will allow you to keep your productivity up. And many time
						management tools can help with that goal. They are complementary to time
						tracking and you can use them to help optimize your workflow. Here is a
						survey of a few useful tools to get you started.
					</ArticleCopy>

					<ArticleList gutters>

						<ArticleListItem component="div" title="LogSpace">
							<p>
								We built LogSpace to handle our
								{' '}
								<RouterLink to="/freelance-time-tracking/">freelance</RouterLink>
								{' '}activities with as little overhead as possible. Now look at us!
								{' '}<RouterLink to="/features/invoicing/">Invoicing</RouterLink>
								{' '}has never been easier,
								including time tracking and expenses. And we're glad to share the
								experience with you too.
							</p>
							<p>Check out the <RouterLink to="/timesheet-app/">LogSpace timesheet app</RouterLink></p>
						</ArticleListItem>

						<ArticleListItem component="div" title="Trello">
							<p>
								Trello is a popular app that is great for managing projects whether
								you're solo or working with a team. With this app, you can use boards,
								cards, and lists to help organize and prioritize your projects. If
								you've ever heard of
								{' '}
								<AnchorLink href="https://en.wikipedia.org/wiki/Kanban_(development)">kanban</AnchorLink>,
								then you'd probably feel at home with
								Trello. Kanban is a system of keeping tabs on tasks until they are
								completed. Each task is put in a column based on its current status. The
								word "kanban" is a Japanese word that means "signboard". The kanban
								technique originated from Toyota Motor Corporation as a way to keep tabs
								on manufacturing.
							</p>
							<p>Check out <AnchorLink href="https://trello.com/">Trello</AnchorLink></p>
						</ArticleListItem>

						<ArticleListItem component="div" title="Google Docs">
							<p>
								Whether you're working solo or on a team, it's now super easy to write
								and share documents. We use it to collaborate on articles, write
								spreadsheets, and keep track of other information. You can also share
								drawings, presentations, and forms.
							</p>
							<p>Check out <AnchorLink href="https://www.google.com/docs/about/">Google Docs</AnchorLink></p>
						</ArticleListItem>

						<ArticleListItem component="div" title="MindNode">
							<p>
								MindNode is an app that lets you brainstorm and get ideas recorded
								quickly. It is convenient for when you need to create outlines for
								reports, websites, articles, or other projects. It has a minimal design
								that helps you focus on getting your thoughts down without being
								distracted.
							</p>
							<p>Check out <AnchorLink href="https://mindnode.com/">MindNode</AnchorLink></p>
						</ArticleListItem>

						<ArticleListItem component="div" title="Nimbus Note">
							<p>
								Nimbus Note allows you to create notes, docs, lists, wikis, and more. There
								is also a feature that allows you to take a screenshot and have it saved
								to your account. You can then jot down any notes associated with it.
							</p>
							<p>Check out <AnchorLink href="https://nimbusweb.me/">Nimbus Note</AnchorLink></p>
						</ArticleListItem>

						<ArticleListItem component="div" title="Dropbox">
							<p>
								This app allows you to store and access your files from anywhere. It has
								an intuitive design that allows you to organize important documents.
								Plus the files will be synced across all your devices.
							</p>
							<p>Check out <AnchorLink href="https://www.dropbox.com/">Dropbox</AnchorLink></p>
						</ArticleListItem>

						<ArticleListItem component="div" title="Evernote">
							<p>
								This is a popular note-taking app that can help with productivity. It
								allows you to capture ideas in various formats such as notes, audio, and
								pictures. It's useful for managing projects, keeping records, and
								planning.
							</p>
							<p>Check out <AnchorLink href="https://evernote.com/">Evernote</AnchorLink></p>
						</ArticleListItem>

						<ArticleListItem component="div" title="TomatoTimer">
							<p>
								This is a simple web app that implements the
								{' '}
								<AnchorLink href="https://en.wikipedia.org/wiki/Pomodoro_Technique">Pomodoro time management technique</AnchorLink>.
								It is an easy to use app that is focused on only using the
								Pomodoro technique. As a result, it has a very focused and clean design.
							</p>
							<p>Check out <AnchorLink href="https://tomato-timer.com/">TomatoTimer</AnchorLink></p>
						</ArticleListItem>

						<ArticleListItem component="div" title="Bitwarden">
							<p>
								You may have read about compromised online accounts in the news at some
								point. One way to help protect yourself from this outcome is to secure
								your online accounts with strong per-account passwords. Bitwarden allows
								you to create and use randomly generated passwords for your online
								accounts. Their cloud syncing service lets you access your data
								on any device.
							</p>
							<p>Check out <AnchorLink href="https://bitwarden.com/">Bitwarden</AnchorLink></p>
						</ArticleListItem>

					</ArticleList>

					<ArticleCopy>
						Since you've got a unique working style and goals, we can't say there's one
						set of time management tools that is the solution for everyone. Check out a
						few of these tools, and see which ones help you boost productivity and
						improve your workflow.  For more resources, see our
						{' '}
						<RouterLink to="/time-management-101/">Time Management 101</RouterLink> guide.
					</ArticleCopy>

					<ArticleCopy disclaimer>
						Note: While the information in this article is presented in the hope that it
						will be useful, Moment Software LLC does not endorse or recommend any
						particular product or service. The responsibility to determine the
						suitability of any mentioned product or service and the decision to use or
						not to use them remains with you.
					</ArticleCopy>

				</ArticleBody>

			</ArticlePage>
		</AppLayout>
	);
}
export const query = graphql`{
  headerImg: file(relativePath: {eq: "images/articles/time-management-tools/hunter-haley-s8OO2-t-HmQ-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH) }
  }
}
`;
